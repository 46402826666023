import countries from "./countries";
import parsePhoneNumberFromString from "libphonenumber-js";

export const fullMobileNumberWithCountry = (phone: any, country: any) => {
  let countryCode = countries.find((c) => c.code === country);
  const phoneNumber = parsePhoneNumberFromString(
    `+${countryCode?.phone}${phone}`,
    country
  );

  const mobileNumber = phoneNumber?.number
    ? `+${phoneNumber?.countryCallingCode} ${phoneNumber?.nationalNumber}`
    : "";

  return mobileNumber;
};
