import { http } from "api/http";

const getAppliedDscAdmin = (data) => {
  return http.get("/dsc-register/get-applied-dsc-admin");
};

const getAppliedDscView = ({ queryKey }) => {
    console.log(queryKey,'querykey')
    return http.get(`/dsc-register/get-applied-dsc/${queryKey[1]}`);
     // Pass the ID parameter to the endpoint
  };

  const updateDscApply= ({ id, data }: any) => {


    return http.put(`/dsc-register/update-applyDsc/${id}`, data);
  };

export { getAppliedDscAdmin,getAppliedDscView,updateDscApply };